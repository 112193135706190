.gallery {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: auto auto auto auto;
  margin-bottom: 3rem;

  &.gallery-svg {
    grid-gap: 5rem;

    .image {
      align-items: center;
      display: flex;
      filter: brightness(1.2) saturate(0) contrast(.7);
      justify-content: center;
      transition: filter .4s ease-out;

      &:hover {
        filter: brightness(1) saturate(1) contrast(1);
        transition: filter .8s ease-in;
      }
    }
  }
}

@media screen and (max-width: $break-xlarge) {
  .gallery {
    grid-template-columns: auto auto auto;
  }
}

@media screen and (max-width: $break-medium) {
  .gallery {
    grid-template-columns: auto auto;
  }
}
