.title-page {
  align-items: center;
  display: flex;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  width: 100%;

  .title-video-container {
    height: 102%;
    left: -1%;
    position: absolute;
    width: 102%;
    z-index: 5;

    .title-video {
      bottom: 0;
      left: 50%;
      min-height: 102%;
      min-width: 102%;
      position: absolute;
      transform: translate(-50%, 0);
    }
  }

  .container {
    color: $white;
    display: flex;
    justify-content: flex-end;
    position: relative;
    z-index: 15;

    .title-content {
      max-width: 560px;
      padding: 3rem 0 6rem;
      visibility: hidden;

      h1,
      h2 {
        filter: $menu-shadow;
      }
    }
  }
}

.pricing-content {
  display: flex;
  justify-content: space-between;

  > div {
    color: $white;
    display: flex;
    flex-direction: column;
    max-width: 340px;
    padding: 1.5rem 2rem 1rem;
    position: relative;
    text-align: center;
    visibility: hidden;
    width: 30%;

    &.blue {
      margin-bottom: -1.5rem;
      top: -1.5rem;
    }

    &.purple {
      margin-bottom: -3rem;
      top: -3rem;
    }

    &::after {
      background: inherit;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transform: skewY(-5deg);
      transform-origin: top left;
      width: 100%;
      z-index: -1;
    }

    ul {
      font-size: .9rem;
      margin: 1rem 0 5rem;
      text-align: left;
    }

    .link-button {
      margin-right: 0;
      margin-top: auto;
      min-width: 0;

      &::after {
        transform: skewX(10deg) translate(-100%, -50%);
      }

      &:hover {
        &::after {
          transform: skewX(-40deg) translate(-20%, -50%);
        }
      }
    }
  }
}

@media screen and (max-width: $break-large) {
  .pricing-content {
    align-items: center;
    flex-direction: column;

    > div {
      width: 100%;

      &.blue,
      &.purple,
      &.yellow {
        margin-bottom: 4rem;
        top: 0;
      }

      &.purple {
        margin-bottom: 1rem;
      }
    }
  }
}

.services-content {
  background: $blue-light;
  color: $white;
  display: flex;
  position: relative;
  text-align: center;

  > div {
    display: flex;
    flex-direction: column;
    padding: 10rem 2rem 4rem;
    width: calc(100% / 3);

    &:nth-child(2) {
      background: $blue-light2;
    }

    &:last-child {
      background: $blue;
    }
  }

  .services-title {
    left: 50%;
    max-width: calc(100% / 3);
    position: absolute;
    top: 4rem;
    transform: translateX(-50%);
  }

  .service {
    margin: 0 auto;
    max-width: 340px;
    text-align: left;

    p {
      font-size: .8rem;
    }

    figure {
      margin: 2rem auto;

      img {
        display: block;
        margin: 0 auto;
      }
    }
  }
}

@media screen and (max-width: $break-large) {
  .services-content {
    flex-direction: column;

    > div {
      padding: 3rem 2rem;
      width: 100%;
    }

    .service {
      figure {
        margin: 0 auto 2rem;
      }
    }

    .services-title {
      left: auto;
      margin-bottom: 2rem;
      max-width: none;
      position: static;
      text-align: left;
      top: auto;
      transform: translateX(0);
      width: 100%;
    }
  }
}

.other-services-content {
  visibility: hidden;
}

@media screen and (max-width: $break-large) {
  .other-services-content {
    img {
      max-height: 260px;
    }
  }
}
