html {
  font-size: 16px;
}

body {
  @extend %zero-margin-padding;
  background-color: $white;
  color: $black-light;
  font-family: 'Montserrat', sans-serif;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  transition: padding .3s;
  width: 100%;
}

* {
  box-sizing: border-box;
}


a {
  color: $black-light;
  outline: 0;
  text-decoration: underline;
  transition: color .2s;

  &:hover {
    color: $black;
  }
}

img {
  height: auto;
  max-width: 100%;
  width: auto;
}

h1, h2, h3, h4 {
  font-family: 'Roboto Slab', serif;
}

h1 {
  font-size: 2.4rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

h2 {
  font-size: 1.8rem;
  font-weight: 600;
}

h3 {
  font-size: 1.4rem;
  font-weight: 600;
}

big {
  font-family: 'Roboto Slab', serif;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1;
}

h4 {
  font-size: 1.1rem;
  font-weight: 500;
}

p {
  font-size: .8rem;
}

hr {
  margin: 3rem 0;
}

.hidden {
  display: none;
}

.c {
  @extend %clear;
}

.padded-bottom {
  padding: 0 0 4rem;
}

.padded {
  padding: 4rem 15px;

  &.padded-bottom {
    padding: 0 15px 4rem;
  }

  &.padded-top {
    padding: 8rem 15px 0;
  }
}

.container-first {
  padding-top: 12rem;
}

.pswp__caption__center {
  text-align: center;

  small {
    padding-left: 1rem;
  }
}

.photoswipe {
  &.left {
    float: left;
    margin: 0 1rem 1rem 0;
  }

  &.right {
    float: right;
    margin: 0 0 1rem 1rem;
  }
}

@media screen and (max-width: $break-large) {
  .padded-bottom {
    padding: 0 0 3rem;
  }

  .padded {
    padding: 3rem 15px;

    &.padded-bottom {
      padding: 0 15px 3rem;
    }

    &.padded-top {
      padding: 6rem 15px 0;
    }
  }

  .container-first {
    padding-top: 6rem;
  }

  h2 {
    font-size: 1.6rem;
  }

  h3,
  big {
    font-size: 1.4rem;
  }
}
