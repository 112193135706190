.contacts {
  position: relative;

  .overlay-skew {
    &::after {
      height: 100%;
    }
  }

  .below-skew {
    padding-bottom: 0;
  }

  .contacts-content {
    overflow: hidden;
  }

  .overlay {
    background: url('../images/mail.svg') no-repeat 30% top;
    left: 0;
    opacity: 1;
    top: 0;
    z-index: 15;
  }

  .container {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 20;

    .content {
      p {
        font-size: .8rem;
      }
    }
  }
}

.contact-form {
  margin-left: 30%;

  form {
    .field.id_captchafield_1_1 {
      img.captcha {
       width: 150px;
       margin-bottom: 20px;
      }}
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    label {
      display: none;
    }

    input,
    textarea {
      border: 0;
      font-size: .8rem;
      margin-bottom: 1rem;
      padding: .8rem;
      width: 100%;
    }

    .field {
      width: calc(50% - .5rem);

      &.id_text {
        width: 100%;
      }
      
      small {
        color: $red;
        font-size: .7rem;
        position: relative;
        top: -1rem;
      }
    }

    textarea {
      min-height: 160px;
    }

    .link-button {
      margin: 0 0 0 auto;
    }

    .form-message {
      width: 100%;
    }
  }
}

@media screen and (max-width: $break-large) {
  .contacts {
    .overlay {
      display: none;
    }

    .container {
      flex-wrap: wrap;

      .content {
        margin-bottom: 3rem;
        width: 100%;
      }
    }
  }

  .contact-form {
    margin-left: 0;
  }
}
