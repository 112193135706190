.reveal {
  visibility: hidden;
}

.light {
  background: $white-dark2;
}

.dark {
  background: $gray-light;
}

.darker {
  background: $gray-dark;
}

.yellow,
.purple,
.blue {
  background: $yellow;
  color: $white;

  a {
    color: $white;
  }
}

.purple {
  background: $purple;
}

.blue {
  background: $blue;
}

.columns {
  column-count: 2;
  column-gap: 2rem;
}

.page-title {
  margin-bottom: 3rem;
  visibility: hidden;
}

.center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 3rem;
}

@media screen and (max-width: $break-medium) {
  .columns {
    column-count: 1;
    column-gap: 0;
  }
}

.text-with-image,
.text-with-image-reverse,
.text-with-image-vertical,
.text-with-image-vertical-reverse,
.text-with-image-center,
.text-with-image-unscale,
.text-with-image-unscale-reverse {
  align-items: center;
  display: flex;
  margin: 3rem 0;

  > * {
    visibility: hidden;
  }

  > img,
  > figure,
  > .bubble {
    flex-shrink: 0;
    margin-right: 3rem;
  }

  > figure {
    max-width: 30%;
  }

  > .bubble {
    margin-right: 8rem;
  }
}

.text-with-image-reverse,
.text-with-image-vertical-reverse,
.text-with-image-unscale-reverse {
  flex-direction: row-reverse;

  > img,
  > figure,
  > .bubble {
    flex-shrink: 0;
    margin-left: 3rem;
    margin-right: 0;
  }

  > .bubble {
    margin-left: 8rem;
  }
}

.text-with-image-center {
  flex-direction: column;

  > * {
    visibility: visible;
  }

  > img,
  > figure,
  > a {
    margin: 0 auto 2rem;
  }
}

.text-with-image-unscale,
.text-with-image-unscale-reverse {
  > figure {
    max-width: 100%;
  }
}

@media screen and (max-width: $break-large) {
  .text-with-image,
  .text-with-image-reverse,
  .text-with-image-vertical,
  .text-with-image-vertical-reverse,
  .text-with-image-unscale,
  .text-with-image-unscale-reverse {
    flex-direction: column;
    margin: 2rem 0;

    > img,
    > figure {
      margin: 0 auto 2rem;
    }

    > figure {
      max-width: 100%;
    }

    > .bubble {
      margin: 2rem auto 3rem;
      transform: scale(.9);
    }
  }

  .text-with-image-vertical,
  .text-with-image-vertical-reverse,
  .text-with-image-unscale-reverse {
    flex-direction: column-reverse;

    > img,
    > figure {
      margin: 2rem auto 0;
    }

    > .bubble {
      margin: 5rem auto 3rem;
    }
  }
}

div {
  &[class^='multicolumn'] {
    margin: 1rem 0;

    > div {
      padding-right: 1rem;

      &:last-child {
        padding-right: 0;
      }
    }
  }
}

@media screen and (max-width: $break-medium) {
  div {
    &[class^='multicolumn'] {
      > div {
        float: none !important;
        margin-bottom: 2rem;
        padding-right: 0;
        width: 100% !important;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.slide-down {
  .slide-down-title {
    cursor: pointer;
    display: inline-block;
    position: relative;
    text-transform: uppercase;
    user-select: none;

    &::after {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid $black-light;
      content: '';
      display: inline-block;
      margin-left: 8px;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      transform-origin: center;
      transition: transform .5s;
    }

    &.active {
      &::after {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  .slide-down-content {
    display: none;
    padding-bottom: 2rem;
  }
}

.unblur {
  transition: filter .3s, transform .9s;
}

.blur {
  filter: blur(10px);
  transform: scale(1.08);
  transform-origin: center;
  transition: filter 1.7s .3s, transform 2s;
}

.link-button {
  background: $white;
  border: 0;
  box-shadow: 4px 4px 8px $black-transparent15;
  color: $black-light;
  display: inline-block;
  font-size: .9rem;
  font-weight: 600;
  margin: 0 1rem 1rem 0;
  min-width: 240px;
  outline: none;
  overflow: hidden;
  padding: .7rem .6rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: color .4s ease-out;
  user-select: none;
  width: auto;

  span {
    color: $black-light;
    position: relative;
    transition: color .4s ease-out;
    z-index: 12;
  }

  &::before {
    background-color: $white-transparent;
    border-radius: 100%;
    content: '';
    display: block;
    left: 50%;
    padding-top: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    z-index: 11;
  }

  &:active {
    &::before {
      padding-top: 120%;
      transition: width .2s ease-out, padding-top .2s ease-out;
      width: 120%;
    }
  }

  &::after {
    background: $black-light;
    content: '';
    display: block;
    height: 140%;
    left: 0;
    position: absolute;
    top: 50%;
    transform: skewX(10deg) translate(-100%, -50%);
    transition: transform .2s ease-out, color .8s ease-out;
    width: 200%;
    z-index: 10;
  }

  &.blue,
  &.yellow,
  &.purple {
    color: $black-light;
  }

  &.blue {
    &::after {
      background: $blue;
      transform: skewX(10deg) translate(-96%, -50%);
    }
  }

  &.yellow {
    &::after {
      background: $yellow;
      transform: skewX(10deg) translate(-96%, -50%);
    }
  }

  &.purple {
    &::after {
      background: $purple;
      transform: skewX(10deg) translate(-96%, -50%);
    }
  }

  &:focus {
    outline: 0;
  }

  &:hover {
    color: $white;
    text-decoration: none;
    transition: color .8s ease-out;

    span {
      color: $white;
      transition: color .8s ease-out;
    }

    &::after {
      transform: skewX(-40deg) translate(-20%, -50%);
      transition: transform .4s ease-in;
    }
  }
}

.overlay,
.overlay-skew {
  background: $black;
  height: 100%;
  opacity: .2;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
}

.overlay-skew {
  background: transparent;
  opacity: 1;
  z-index: 30;

  &::after {
    background: $white;
    bottom: -96%;
    content: '';
    display: block;
    height: 102%;
    position: absolute;
    transform: skewY(-5deg);
    transform-origin: top left;
    width: 100%;
  }

  &.overlay-skew-top {
    z-index: 5;

    &::after {
      top: 0;
      transform-origin: top right;
    }

    &.yellow {
      &::after {
        background: $yellow;
      }
    }

    &.purple {
      &::after {
        background: $purple;
      }
    }

    &.blue {
      &::after {
        background: $blue;
      }
    }
  }
}

.below-skew {
  padding-bottom: calc((100vw / .9961946 * .08715574));
  padding-top: 1px;
  position: relative;
  top: calc(100vw / .9961946 * .08715574);
  z-index: 10;
}

.relative {
  position: relative;
}

.bubble {
  background: none;

  &::before {
    border-radius: 100%;
    box-shadow: 4px 4px 8px $black-transparent15;
    content: '';
    display: block;
    left: 50%;
    padding-top: 150%;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 150%;
    z-index: -1;
  }

  &.blue {
    &::before {
      background: $blue;
    }
  }

  &.yellow {
    &::before {
      background: $yellow;
    }
  }

  &.purple {
    &::before {
      background: $purple;
    }
  }

  .link-button {
    margin: 0 0 2rem;
  }

  .price {
    margin: 0;

    h1 {
      line-height: .8;
    }
  }
}

.price {
  margin: auto 0 3rem;

  h1 {
    font-size: 5rem;
    line-height: .5;

    small {
      font-size: 2rem;
    }
  }
}

.with-caret,
.with-caret-block,
.side-menu .side-menu-container a {
  color: $black-light;
  display: inline-block;
  font-weight: 500;
  padding-right: .5rem;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    color: $black-light;
    text-decoration: none;

    &::after {
      transform: translateX(.3rem);
    }
  }

  &:active {
    transform: translate(1px, 1px);
  }

  &::after {
    background: url('../images/caret-right.svg') no-repeat center;
    content: '';
    display: inline-block;
    height: 9px;
    margin-left: .3rem;
    transition: transform .6s ease-out;
    width: 6px;
  }

  &.white {
    color: $white;

    &:hover {
      color: $white;
    }

    &::after {
      filter: invert(1);
    }
  }
}

.with-caret-block {
  display: block;
  margin-bottom: .5rem;
}

.table {
  margin: 3rem 0;
  overflow-x: auto;

  table {
    border: 0;
    border-collapse: collapse;
    width: 100%;

    thead {
      font-family: 'Roboto Slab', serif;

      tr {
        background: $gray;
      }
    }

    tr {
      background: $white-dark2;

      &:nth-child(even) {
        background: $white-dark3;
      }
    }

    th,
    td {
      border: 0;
      padding: .6rem;
      text-align: center;

      &:first-child {
        text-align: left;
      }
    }
  }
}

.carousel-slick {
  margin: 0 auto;
  max-width: calc(100% - 60px);

  .slick-prev,
  .slick-next {
    background: url('../images/arrow-left.svg') no-repeat center;
    height: 27px;
    transition: transform .2s;
    width: 16px;

    &:active {
      transform: translate(-8%, -50%);
    }

    &::before {
      content: '';
    }
  }

  .slick-next {
    background: url('../images/arrow-right.svg') no-repeat center;

    &:active {
      transform: translate(8%, -50%);
    }
  }

  &.short {
    .slick-prev,
    .slick-next {
      filter: invert(1) brightness(2);
    }
  }

  .slick-track {
    align-items: center;
    display: flex;
  }

  .slick-slide {
    figure {
      align-items: center;
      display: flex;
      filter: brightness(1.2) saturate(0) contrast(.7);
      justify-content: center;
      margin: 0;
      padding: 0 2rem;
      transition: filter .4s ease-out;

      &:hover {
        filter: brightness(1) saturate(1) contrast(1);
        transition: filter .8s ease-in;
      }
    }

    .text-with-image-center {
      > * {
        max-width: 80%;
      }

      img,
      figure {
        max-width: 70%;
      }
    }
  }
}

.brand-logo-display {
  .multicolumn2 {
    display: flex;
    align-items: center;
  }

  @media only screen and (max-width: 767px) {
    .multicolumn2 {
      display: block;
    }
  }

  & a {
    text-decoration: none;
  }
}