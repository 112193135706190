.side-menu {
  .side-menu-container {
    flex-shrink: 0;
    font-family: 'Roboto Slab', serif;
    font-size: 1.2rem;
    margin-right: 5rem;
    max-width: 280px;
    position: relative;

    ul {
      @extend %zero-margin-padding;
      list-style: none;
      position: sticky;
      top: 120px;

      li {
        left: 0;
        padding-right: 0;
      }
    }

    a {
      text-decoration: none;

      &.active {
        text-decoration: underline;
      }
    }
  }

  .side-menu-content {
    display: flex;

  }
}

@media screen and (max-width: $break-large) {
  .side-menu {
    .side-menu-container {
      margin-bottom: 3rem;
      margin-right: 0;
      max-width: 100%;

      ul {
        position: static;
      }
    }

    .side-menu-content {
      flex-direction: column;
    }
  }
}
