.news-list-title {
  align-items: baseline;
  display: flex;
  padding-top: 1rem;

  > * {
    &:first-child {
      padding-right: 2rem;
    }
  }
}

.news-list {
  margin-bottom: 2rem;

  a {
    color: $white;
  }
}

.post-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  visibility: hidden;
  width: 31%;

  &:last-child {
    margin-bottom: 0;
  }

  .blog-content {
    width: 100%;

    header {
      a {
        text-decoration: none;
      }

      h3 {
        margin-bottom: 1rem;
      }

      .date {
        margin: 0;
      }
    }
  }
}

.blog-list,
.blog-latest-entries {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > .post-item {
    &:nth-child(4n) {
      width: 100%;
    }
  }

  .blog-visual {
    margin-bottom: .6rem;
  }
}

.blog-latest-entries {
  .post-item {
    margin-bottom: 0;
  }
}

@media screen and (max-width: $break-large) {
  .blog-latest-entries {
    .post-item {
      margin-bottom: 3rem;
      width: 100%;
    }
  }

  .blog-list,
  .blog-latest-entries {
    .post-item {
      width: 100%;

      &:nth-child(4n) {
        width: 100%;

        .blog-content {
          border: 0;
          padding-top: 0;
        }
      }
    }
  }

  .news-list {
    margin-bottom: 6rem;
  }
}

.post-detail {
  visibility: hidden;

  > header {
    margin-bottom: 2rem;

    .date {
      margin: 0;
    }

    h2 {
      span {
        padding-right: .6rem;
      }
    }
  }

  .blog-visual {
    float: left;
    margin: 0 1rem 1rem 0;
  }

  .blog-content {
    margin-bottom: 2rem;
  }

  .blog-intro {
    font-size: 1.2rem;
    font-weight: 600;

    p {
      font-size: 1.2rem;
      font-weight: 600;
    }
  }
}

@media screen and (max-width: $break-large) {
  .post-detail {
    .blog-visual {
      float: none;
      margin: 0 0 1rem;
    }
  }
}

.pagination {
  display: block;
  visibility: hidden;
}

.blog-title {
  .pagination {
    margin-bottom: 3rem;
  }
}
