.top-container {
  .menu-button {
    background: none;
    border: 0;
    cursor: pointer;
    display: none;
    height: 21px;
    outline: 0;
    padding: 0;
    position: relative;
    transition: transform .3s;
    width: 30px;

    &:active,
    &:focus {
      background: none;
      outline: 0;
      padding: 0;
    }

    .stick {
      background: $white;
      display: block;
      filter: $menu-shadow;
      height: 3px;
      opacity: 1;
      outline: 1px solid transparent;
      padding: 0;
      position: absolute;
      top: 0;
      transform-origin: 0 -2px;
      transition: opacity .3s, background 1s, transform .3s, filter 1s;
      width: 30px;

      &:nth-child(2) {
        top: 9px;
      }

      &:nth-child(3) {
        top: 18px;
        transform-origin: 0 5px;
      }
    }

    &.active {
      transform: translateX(7px);

      .stick {
        &:nth-child(1) {
          transform: rotate(43deg);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(-43deg);
        }
      }
    }
  }
}

@media screen and (max-width: $break-xlarge) {
  .top-container {
    .menu-button {
      display: block;
      margin: 0;
    }
  }
}
