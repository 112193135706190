.top-container {
  .top {
    height: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;

    .top-menu {
      @extend %zero-margin-padding;
      align-items: center;
      display: flex;
      font-size: .82rem;
      list-style: none;

      > li {
        padding: 62px 0;
        transition: padding 1s;
      }

      li {
        left: 0;
        margin-right: 2.6rem;
        padding-right: 0;

        &.parent {
          &::after {
            background: url('../images/caret-right.svg') no-repeat center;
            content: '';
            display: inline-block;
            filter: invert(1) brightness(2) $menu-shadow;
            height: 9px;
            margin-left: .3rem;
            transform: rotate(90deg);
            transition: filter 1s;
            width: 6px;
          }
        }

        &:hover {
          ul {
            opacity: 1;
            transform: translateY(0);
            transition: transform 1s ease-out, opacity 1s ease-out, background 1s, box-shadow 1s;
          }
        }
      }

      a {
        color: $white;
        filter: $menu-shadow;
        font-weight: 500;
        text-decoration: none;
        text-transform: uppercase;
        transition: filter 1s, color 1s;
      }

      ul {
        background: transparent;
        box-shadow: 0;
        left: -1rem;
        list-style: none;
        opacity: 0;
        padding: 1.4rem 1rem 1rem;
        position: absolute;
        transform: translateY(-200%);
        transition: transform .4s .3s ease-in, opacity .4s .3s ease-in, background 1s, box-shadow 1s;

        &:hover {
          opacity: 1;
          transform: translateY(0);
          transition: transform 1s ease-out, opacity 1s ease-out, background 1s, box-shadow 1s;
        }

        li {
          font-size: .72rem;
          margin-top: .6rem;
        }
      }
    }

    .top-bg {
      background: $transparent;
      bottom: 0;
      height: 300%;
      position: absolute;
      transition: background .6s ease-in;
      width: 100%;
    }

    figure {
      margin: 0;
    }

    > .container {
      align-items: center;
      display: flex;
      justify-content: right;
    }

    * {
      user-select: none;
    }

    .logo {
      align-items: center;
      display: flex;
      filter: $menu-shadow;
      margin-right: auto;
      transition: filter 1s;

      > * {
        &:first-child {
          filter: invert(0);
          margin-right: 12px;
          position: relative;
          top: 2px;
          transition: filter 1s;
        }
      }

      &:hover {
        text-decoration: none;
      }
    }

    .language {
      @extend %zero-margin-padding;
      list-style: none;
      margin-right: 1rem;

      a {
        color: $white;
        filter: $menu-shadow;
        font-size: .8rem;
        font-weight: 800;
        text-decoration: none;
        text-transform: uppercase;
        transition: filter 1s, color 1s;
      }
    }

    .top-icons {
      align-items: center;
      display: flex;
      filter: $menu-shadow invert(0);
      font-size: 0;
      justify-content: space-between;
      transition: filter 1s;

      img {
        max-height: 20px;
      }
    }

    &.colored,
    &.active,
    &.simple {
      box-shadow: 0 0 3px $black-transparent15;

      .top-menu {
        > li {
          padding: 20px 0;
        }

        li {
          &.parent {
            &::after {
              filter: invert(0);
            }
          }
        }

        ul {
          background: $white;
          box-shadow: 0 0 3px $black-transparent15;
          z-index: -1;
        }
      }

      .top-icons,
      .logo,
      .stick,
      .language a,
      .top-menu a {
        color: $black-light;
        filter: $no-shadow;
      }

      .logo {
        > * {
          &:first-child {
            filter: invert(1);
          }
        }
      }

      .stick {
        background: $black-light;
      }

      .top-icons {
        filter: $no-shadow invert(1);
      }

      .top-bg {
        background: $white;
      }
    }
  }
}

.cms-toolbar-expanded {
  .top {
    top: 46px;
  }
}

@media screen and (max-width: $break-xlarge) {
  .top-container {
    .top {
      padding: 16px 0;
      width: 100%;

      .top-menu {
        display: none;
      }

      &.colored,
      &.active {
        .top {
          padding: 16px 0;
        }
      }

      .top-icons {
        margin-right: 2rem;
      }
    }
  }
}

@media screen and (max-width: $break-medium) {
  .top-container {
    .top {
      padding: 12px 0;

      &.colored,
      &.active {
        padding: 12px 0;
      }
    }
  }
}

.main-menu {
  align-items: center;
  background: $yellow;
  display: flex;
  height: calc(100% - 56px);
  left: 0;
  max-height: 100%;
  opacity: 0;
  overflow-y: auto;
  pointer-events: none;
  position: fixed;
  top: 56px;
  transform: translateY(100%);
  transition: opacity .3s ease-in, transform .3s ease-in;
  width: 100%;
  z-index: 800;

  &.active {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
    transition: opacity .6s ease-out, transform .6s ease-in;
  }

  a {
    color: $white-dark2;
    text-decoration: none;

    &:hover {
      color: $white;
      text-decoration: none;
    }
  }

  .menu-content {
    margin: auto;

    ul {
      @extend %zero-margin-padding;
      list-style: none;

      .selected {
        > a {
          color: $white;
          text-decoration: underline;
        }
      }
    }

    > ul {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: 100%;
      overflow: auto;
      padding: 1.6rem;


      > li {
        font-size: 1.4rem;
        font-weight: 500;
        padding: 0 3rem 2rem 0;

        > ul {
          margin-left: 1rem;

          > li {
            font-size: 1rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $break-large) {
  .main-menu {
    height: calc(100% - 48px);
    top: 48px;
  }
}
