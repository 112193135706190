.content {
  font-size: .8rem;
  position: relative;
}

.container {
  ul {
    @extend %zero-margin-padding;

    li {
      left: 1rem;
      padding-right: 1rem;
      position: relative;
    }
  }
}

.content-wrapper {
  visibility: hidden;

  a {
    color: $blue-dark;
    font-weight: 500;
  }
}

@media screen and (max-width: $break-medium) {
  .content-wrapper {
    text-align: center;
  }
}
